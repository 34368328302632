import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import buttonLeft from '../../images/sx.png'
import buttonCenter from '../../images/center.png'
import buttonRight from '../../images/dx.png'

export const OurGamesWrapper = styled.div`
  position: relative;
  padding: 70px 15px 0;
  .subscribe-button {
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    transition: 0.15s ease-in-out;
    filter: brightness(0.9);
    &:hover {
      filter: brightness(1);
    }
    button {
      height: 44px;
      background-image: url(${buttonCenter});
      background-repeat-x: repeat;
      background-color: transparent;
      background-size: 44px;
      background-repeat-y: no-repeat;
      background-position: center;
      padding: 0;
      position: relative;
      z-index: 1001;
      .btn-text {
        position: relative;
        top: -3px;
      }
      &:before {
        position: absolute;
        content: '';
        left: -40px;
        top: -7px;
        width: 43px;
        height: 58px;
        background-size: contain;
        background-image: url(${buttonLeft});
        z-index: 1000;
      }
      &:after {
        position: absolute;
        content: '';
        right: -40px;
        top: -7px;
        width: 43px;
        height: 58px;
        background-size: contain;
        background-image: url(${buttonRight});
        z-index: 1000;
      }
    }
  }
  @media (max-width: 990px) {
    padding: 70px 15px 30px;
    .subscribe-button {
      position: static;
      margin: -60px auto 40px;
      display: block;
      text-align: center;
      transform: none;
    }
  }
  @media (max-width: 575px) {
    padding: 60px 15px 0;
  }
`;

export const OurGamesPageTitle = styled.div`
  margin-bottom: 45px;
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  p {
    color: ${themeGet('colors.lightTextColor', '#757575')};
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.primaryText', '#292929')};
    line-height: 1.53;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const OurGamesRow = styled.div`
  margin: 0 -15px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 990px) {
    margin: 0 -10px;
  }
`;

export const OurGamesCol = styled.div`
  width: 100%;
  margin: 0 0 30px;
  background: ${themeGet('colors.primaryCardDetail', '#292929')};
  flex: 0 0 calc(25% - 10px);
  @media (max-width: 1024px) {
    flex: 0 0 calc(50% - 10px);
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
  }
`;